import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

const LottieAnimation = ({ path, loop = true, autoplay = true }) => {
  const animationContainer = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop,
      autoplay,
      path,
    });

    return () => anim.destroy(); // Limpa a animação ao desmontar o componente
  }, [path, loop, autoplay]);

  return <div style={{
    right: 0,
    height: "40vh",
    width: "100%",
    objectFit: "fill",
    borderRadius: 5,
  }} ref={animationContainer} />;
};

export default LottieAnimation;