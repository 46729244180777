import React, { useRef, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputField } from '../../FormFields';
import { useEffect } from 'react';
import useStyles from './styles';

export default function AddressForm(props) {
  const classes = useStyles();

  const { getCepField, resultApi } = props;

  const [objectResult, setObjectResult] = useState({})
  // const [getApi, setGetApi] = useState(false)

  const mounted = useRef();

  useEffect(() => {
    if (mounted.current.value.length == 8) {
      getCepField(mounted.current.value);
      setObjectResult(resultApi)
    }
  });
  const {
    formField: {
      firstName,
      number,
      addressComplement,
      city,
      state,
      zipcode,
      country,
      whatsapp,
      birthday
    }
  } = props;
  return (
    <React.Fragment>
      <Typography className={classes.titlePersonalData} variant="h6" gutterBottom>
        Insira seus dados pessoais
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <InputField name={firstName.name}
            label={firstName.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            typeFormat="number"
            inputRef={mounted}
            name={zipcode.name}
            label={zipcode.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            InputLabelProps={{
              shrink: resultApi.uf ? true : false,
            }}
            name={state.name}
            label={state.label}
            value={resultApi.uf}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            InputLabelProps={{
              shrink: resultApi.localidade ? true : false,
            }}
            name={city.name}
            label={city.label}
            value={resultApi.localidade}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            InputLabelProps={{
              shrink: resultApi.logradouro ? true : false,
            }}
            name={country.name}
            label={country.label}
            value={resultApi.logradouro}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            InputLabelProps={{
              shrink: resultApi.bairro ? true : false,
            }}
            name={country.name}
            label={"Bairro"}
            value={resultApi.bairro}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            type="number"
            name={number.name}
            label={number.label}
            fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField
            name={addressComplement.name}
            label={addressComplement.label}
            fullWidth />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField         
            type="tel"     
            mask="(99) 9 9999-9999"
            name={whatsapp.name}
            label={whatsapp.label}
            fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            type="tel"  
            mask="99/99/9999"
            name={birthday.name}
            label={birthday.label} fullWidth
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}


