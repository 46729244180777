import { Route, Routes } from 'react-router-dom';
import CheckoutPage from "../../src/components/CheckoutPage/CheckoutPage"
import Resend from "../../src/components/Resend/index"
import Contact from "../../src/components/ContactForm"
 

function App() {
    return (
        <Routes>
            <Route exact path="/" element={<CheckoutPage />} />
            <Route path="/reenviar" element={<Resend/>} /> 
            <Route path="/contato" element={<Contact/>} /> 
        </Routes>
    );
}

export default App;

 