export default {
  formId: 'checkoutForm',
  formField: {  
    email: {
      name: 'email',
      label: 'E-mail',
      requiredErrorMsg: 'O email é necessário.',
      invalidErrorMsg: 'É necessário um email válido'
    },
    password: {
      name: 'password',
      label: 'Senha',
      requiredErrorMsg: 'A senha é necessária.',
      invalidErrorMsg: 'A senha deve conter pelo menos 8 caracteres'
    },
    firstName: {
      name: 'firstName',
      label: 'Nome completo',
      requiredErrorMsg: 'O nome completo é necessário.',
      invalidErrorMsg: 'O nome deve conter pelo menos 8 caracteres'
    },
    gender: {
      name: 'gender',
      label: 'Gênero',
      requiredErrorMsg: 'Um gênero é necessário.'
    },
    levelEducation: {
      name: 'levelEducation',
      label: 'Grau de escolaridade',
      requiredErrorMsg: 'Um grau de escolaridade é necessário.'
    },
    profession: {
      name: 'profession',
      label: 'Profissão',
      requiredErrorMsg: 'Uma profissão é necessária.',
      placeholder:'Ex: Professor de Libras'
    },
    religion: {
      name: 'religion',
      label: 'Religião',
      requiredErrorMsg: 'Uma religião é necessária.'
    },
    // lastName: {
    //   name: 'lastName',
    //   label: 'Last name*',
    //   requiredErrorMsg: 'Last name is required'
    // },
    number: {
      name: 'number',
      label: 'Número',
      requiredErrorMsg: 'O número é necessário.'
    },
    addressComplement: {
      name: 'addressComplement',
      label: 'Complemento',
      requiredErrorMsg: 'O complemento é necessário.'
    },
    // address1: {
    //   name: 'address1',
    //   label: 'Address Line 1*',
    //   requiredErrorMsg: 'Address Line 1 is required'
    // },
    address2: {
      name: 'address2',
      label: 'Address Line 2'
    },
    city: {
      name: 'city',
      label: 'Cidade',
      requiredErrorMsg: 'City is required'
    },
    state: {
      name: 'state',
      label: 'Estado'
    },
    zipcode: {
      name: 'zipcode',
      label: 'Cep',
      requiredErrorMsg: 'É necessário um cep válido',
      invalidErrorMsg: 'Formato inválido'
    },
    country: {
      name: 'country',
      label: 'Rua',
      requiredErrorMsg: 'Country is required'
    },
    whatsapp: {
      name: 'whatsapp',
      label: 'Whatsapp',
      requiredErrorMsg: 'É necessário um número válido',
      invalidErrorMsg: 'A whatsapp deve conter pelo menos 11 números (Ex: (21) 9 6542-8218)'
    },
    birthday: {
      name: 'birthday',
      label: 'Data de nascimento',
      requiredErrorMsg: 'É necessário uma data válida',
      invalidErrorMsg: 'A data deve conter pelo menos 8 números (Ex:10/02/1991)'
    },
    termsAndConditions: {
      name: 'termsAndConditions',
      label: 'Eu li e concordo com os termos sobre o uso dos meus dados.',
      requiredErrorMsg: 'É necessário aceitar os termos.',
    },
    mothersName: {
      name: 'mothersName',
      label: 'Nome completo da mãe',
      requiredErrorMsg: 'É necessário um nome válido',
      invalidErrorMsg: 'O nome completo da mãe deve conter pelo menos 3 palavras (Ex: 000)'
    },
    voterRegistration: {
      name: 'voterRegistration',
      label: 'Título de eleitor',
      requiredErrorMsg: 'É necessário um título de eleitor válido',
      invalidErrorMsg: 'O título de eleitor deve conter pelo menos 12 números (Ex: 000.000.000.000)'
    },
    electoralZone: {
      name: 'electoralZone',
      label: 'Zona eleitoral',
      requiredErrorMsg: 'É necessário um electoralZone de eleitor válido',
      invalidErrorMsg: 'A zona eleitoral deve conter pelo menos 3 números (Ex: 000)'
    },
    section: {
      name: 'section',
      label: 'Seção',
      requiredErrorMsg: 'É necessário uma seção válida',
      invalidErrorMsg: 'A sessão deve conter pelo menos 8 números (Ex: 000)'
    },
    cpf: {
      name: 'cpf',
      label: 'CPF',
      requiredErrorMsg: 'É necessário uma cpf válido',
      invalidErrorMsg: 'O cpf deve conter pelo menos 12 números (Ex: 000.000.000-00)'
    },
    imageIdUser:{
      name: 'imageIdUser',
      label: 'teste',
      requiredErrorMsg: 'É necessário uma imagem valida.'
    },
    // nameOnCard: {
    //   name: 'nameOnCard',
    //   label: 'Título de eleitor',
    //   requiredErrorMsg: 'É necessário um título de eleitor válido'
    // },
    // cardNumber: {
    //   name: 'cardNumber',
    //   label: 'Card number*',
    //   requiredErrorMsg: 'Card number is required',
    //   invalidErrorMsg: 'Card number is not valid (e.g. 4111111111111)'
    // },
    // expiryDate: {
    //   name: 'expiryDate',
    //   label: 'Expiry date*',
    //   requiredErrorMsg: 'Expiry date is required',
    //   invalidErrorMsg: 'Expiry date is not valid'
    // },
    // cvv: {
    //   name: 'cvv',
    //   label: 'CVV*',
    //   requiredErrorMsg: 'CVV is required',
    //   invalidErrorMsg: 'CVV is invalid (e.g. 357)'
    // }
  }
};
