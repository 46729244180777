import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputField, CheckboxField } from '../../FormFields';
import useStyles from './styles';

export default function BioInformationForm(props) {
    const classes = useStyles();
    const { formField: { email, password, termsAndConditions } } = props;

    return (
        <React.Fragment>
            <Typography className={classes.titlePersonalData} variant="h6" gutterBottom>
                Insira seus dados de Autenticação
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <InputField
                        name={email.name}
                        label={email.label}
                        fullWidth/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <InputField
                        type="password"
                        name={password.name}
                        label={password.label}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <CheckboxField
                        name={termsAndConditions.name}
                        label={termsAndConditions.label}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

