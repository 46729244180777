import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputField, SelectField } from '../../FormFields';
import { levelEducations, genders, religions, professions } from './selectField'
import useStyles from './styles';

export default function ElectoralDataForm(props) {
  const classes = useStyles();
  const {
    formField: { nameOnCard, cardNumber, expiryDate, cvv, mothersName, voterRegistration, electoralZone, section, cpf
      , gender, levelEducation, profession, religion, }
  } = props;

  return (
    <React.Fragment>
      <Typography className={classes.titlePersonalData} variant="h6" gutterBottom>
        Insira seus dados Dados eleitorais
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <InputField
            name={mothersName.name}
            label={mothersName.label}
            fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            type="tel"
            mask="999.999.999.999"
            name={voterRegistration.name}
            label={voterRegistration.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            type="tel"
            mask="999"
            name={electoralZone.name}
            label={electoralZone.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            type="tel"
            mask="999"
            name={section.name}
            label={section.label}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField
            type="tel"
            mask="999.999.999-99"
            name={cpf.name}
            label={cpf.label}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={gender.name}
            label={gender.label}
            data={genders}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectField
            name={levelEducation.name}
            label={levelEducation.label}
            data={levelEducations}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputField
            name={profession.name}
            label={profession.label}
            placeholder={profession.placeholder}
            fullWidth
          />
          {/* <SelectField
            name={profession.name}
            label={profession.label}
            data={levelEducations}
            fullWidth
          /> */}
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectField
            name={religion.name}
            label={religion.label}
            data={religions}
            fullWidth
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}


