export const sendRequest = async (newMessage) => {
    const bodyResponse = {
       
        prompt: newMessage,
        modelId: "pdt",
    };

    const API_URL = "https://brainai-api.com";

    try {
        const response = await fetch(`${API_URL}/legal-assistant`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify(bodyResponse),
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        return await response.text();
    } catch (error) {
        console.error("Error:", error);
        throw error;
    }
};


 