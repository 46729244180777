import checkoutFormModel from './checkoutFormModel';
const {
  formField: { 
    email,
    password,
    firstName,
    gender,
    profession,
    religion,
    levelEducation,
    // lastName,
    // address1,
    number,
    addressComplement,
    city,
    zipcode,
    country,
    whatsapp,
    birthday,
    mothersName,
    voterRegistration,
    electoralZone,
    section,
    cpf,
    termsAndConditions,
    imageIdUser,
    // nameOnCard,
    // cardNumber,
    // expiryDate,
    // cvv
  }
} = checkoutFormModel;

export default {
  [email.name]: '',
  [password.name]: '',
  [firstName.name]: '',
  [gender.name]: '',
  [profession.name]: '',
  [levelEducation.name]: '',
  [religion.name]: '',
  [mothersName.name]: '',
  [voterRegistration.name]: '',
  [electoralZone.name]: '',
  [section.name]: '',
  [cpf.name]: '',
  // [lastName.name]: '',
  // [address1.name]: '',
  [number.name]: '',
  [addressComplement.name]: '',
  [city.name]: '',
  [zipcode.name]: '',
  [country.name]: '',
  [whatsapp.name]: '',
  [birthday.name]: '',
  [termsAndConditions.name]: '',
  [imageIdUser.name]: '',
  // [nameOnCard.name]: '',
  // [cardNumber.name]: '',
  // [expiryDate.name]: '',
  // [cvv.name]: ''
};
