import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  stepper: {
    padding: theme.spacing(3, 0, 5),
    // backgroundColor:'#f5f5f5'  
  },
  titleMain: {
    color: '#5a5a5a',
    fontWeight: '600'
  },
  buttonDontLikePicture: {
    color: '#fff',
    background: 'gray',
    marginLeft: 0
  },
  titlePersonalData: {
    color: "#5a5a5a",
  },
  buttonBox: {
    justifyContent: "center", /* Centraliza os elementos horizontalmente */
    alignItems: "center",

  }
}));
