import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';
import logo from '../../assets/logos/logoHorizontal.png'

export default function Header() {
  const classes = useStyles();

  return (
    <AppBar position="absolute" color="default" className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" color="inherit" className={classes.appBar} noWrap>
          Partido Democrático Trabalhista 
        </Typography> 
        <img src={logo}
          alt="Nature"
          style={{ padding:5 }}
          // className="responsive"
          width="130" height="80"></img>
      </Toolbar>
    </AppBar>
  );
}


