import React, { useEffect, useState } from "react";
import {
  Widget,
  addResponseMessage,
  toggleMsgLoader,
} from "react-chat-widget";
import "react-chat-widget/lib/styles.css";
import "./ChatWidgetStyles.css"; // Importe o CSS personalizado
import { sendRequest } from '../../components/api/chat'

const ChatWidget = () => {
  const [messages, setMessages] = useState([]);
  // const [widgetOpened, setWidgetOpened] = useState(false);

  useEffect(() => { addResponseMessage("Olá, como posso ajudar?") }, []);

  const handleNewUserMessage = async (newMessage) => {
    try {
      toggleMsgLoader();
      const data = await sendRequest(newMessage)
      addResponseMessage(data);
      setMessages((prevMessages) => [...prevMessages, { type: "user", text: newMessage }, { type: "response", text: data }]);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      toggleMsgLoader();
    }
  };

  return (
    <div
      style={{  }}
      className="App"
      maxWidth="sm"
    >
      <Widget
        handleNewUserMessage={handleNewUserMessage}
        title="PDT - Assistente"
        subtitle="Inteligência artificial"
        senderPlaceHolder="Tire sua dúvida..."
        emojis={true}
        autofocus={true}
      />
    </div>
  );
};

export default ChatWidget;
