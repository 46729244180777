export async function detectLabels(imagemBase64) {
    try { 
        var dadosRequisicao = {
            imageBase64: imagemBase64
        };

        var response = await fetch("https://smtp.api-pdt.com.br/v1/affiliated/detectLabels", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(dadosRequisicao)
        });
 
        if (response.ok) {
            var data = await response.json();       
            return data.isIdCardBrasil;
        } else {
            throw new Error("Erro na solicitação: " + response.status);
        }
    } catch (error) {
        // Tratar erros
        console.error(error);
    }
}

export async function postAwsCompareFaces(resultIdCheck, resultImageId) {
    const endpoint = 'https://smtp.api-pdt.com.br/v1/affiliated/compare-affiliated';
    const pessoaImageBase64 = resultIdCheck;
    const identidadeImageBase64 = resultImageId;
    const jsonParams = {
        pessoaImage: pessoaImageBase64,
        identidadeImage: identidadeImageBase64
    };

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(jsonParams)
    };

    try {
        const response = await fetch(endpoint, requestOptions);
        const data = await response.json();    
        return data;
    } catch (error) {
        console.log('Ocorreu um erro:', error);
        return false;
    }
}

 