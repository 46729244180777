import React from 'react';
import { Typography } from '@material-ui/core/';
import { Link } from 'react-router-dom';
import useStyles from './styles';

export default function Footer() {
  const classes = useStyles();
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'SMPDT © '}
      <Link color="inherit" to="https://www.pdt.org.br/">
        www.pdt.org.br/
      </Link>
      {new Date().getFullYear()}
      <br />
      <br />
      <div>
        <Link to="https://www.youtube.com/watch?v=z4u0M6xOt6I" style={{ margin: '0%', color:'#3f51b5', fontWeight:'600' }}>Tutorial de filiação</Link> |
        <Link to="/reenviar" style={{ margin: '0%', color:'#3f51b5', fontWeight:'600'  }}>Reenviar Código </Link> |
        <Link to="/contato" style={{margin: '0%', color:'#3f51b5', fontWeight:'600' }}> Contato</Link> |
        <Link to="https://pdt.org.br/index.php/o-pdt/historia/" target="_blank" style={{ margin: '0%', color:'#3f51b5', fontWeight:'600' }}> História </Link> |
        <Link to="https://pdt.org.br/index.php/estatuto/" target="_blank" style={{margin: '0%', color:'#3f51b5', fontWeight:'600' }}> Estatuto</Link>  
        {/* <Link to="/link5" style={{ margin: '0%' }}> Link5</Link> */}
      </div>
      <br />
      <br />
      <br />
    </Typography>
  );
}
