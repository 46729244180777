import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    main: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Ajuste a altura conforme necessário
    },
    textField: {
        [theme.breakpoints.up('sm')]: {
            width: '50%', // Tamanho do TextField em telas médias e maiores
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%', // Tamanho do TextField em telas pequenas
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
        backgroundColor: 'red',
    },
    // buttons: {
    //     display: 'flex',
    //     justifyContent: 'flex-end',
    // },
    button: {
        marginTop: theme.spacing(6),
        marginLeft: theme.spacing(1)
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%'
    },
    titleMain: {
        color: '#5a5a5a',
        fontWeight: '600',
        fontSize: '1.8rem'
    },
    titleFooter: {
        color: '#5a5a5a',
        fontWeight: '500',
        fontSize: '1rem'
    },
    
    footerDescription: {
        marginTop: theme.spacing(5),
    },
    imageSmall:{
        marginTop: theme.spacing(2),
    }
}));
