import React, { useState } from 'react';
import './app.css';
import { Grid, TextField, Button, Card, CardContent, Typography } from '@material-ui/core';
import { reesendMensgemErro, reesendMensgemSucess } from '../../services/alerts';
import InputMask from 'react-input-mask';

const ImageLogo = (width, height, porcent) => {
    return (
        <img
            src="https://www.pdt.org.br/wp-content/uploads/2021/12/LGO.jpg"
            alt="Nature"
            style={{ width: porcent, height: 'auto' }}
            className="responsive"
            width={width}
            height={height}
        />
    );
};

function App() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        erroMessage: '',
    });

    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        phone: '',
        erroMessage: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        setFormErrors({ ...formErrors, [name]: '' });
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        // Add validation rules here
        if (formData.name.trim() === '') {
            newErrors.name = 'Nome é obrigatório';
            isValid = false;
        }

        if (formData.email.trim() === '') {
            newErrors.email = 'E-mail é obrigatório';
            isValid = false;
        } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
            newErrors.email = 'E-mail inválido';
            isValid = false;
        }

        if (formData.phone.trim() === '') {
            newErrors.phone = 'Telefone é obrigatório';
            isValid = false;
        }

        if (formData.erroMessage.trim() === '') {
            newErrors.erroMessage = 'Mensagem é obrigatória';
            isValid = false;
        }

        setFormErrors(newErrors);
        return isValid;
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (validateForm()) {
            try {
                // Perform the form submission (POST request to the endpoint)
                const response = await fetch('https://smtp.api-pdt.com.br/v1/email/contact-form', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                reesendMensgemSucess("Mensagem enviada com sucesso.");

                if (response.ok) {
                    console.log('Form data submitted successfully!');
                    // Reset the form after successful submission
                    setFormData({
                        name: '',
                        email: '',
                        phone: '',
                        erroMessage: '',
                    });
                } else {
                    console.error('Failed to submit form data.');
                }
            } catch (error) {
                reesendMensgemErro("Erro ao enviar nensagem.");
            }
        }
    };

    return (
        <div className="App">
            {/* <Typography gutterBottom variant="h3" align="center">
                {ImageLogo('200', '200', '30%')}
            </Typography> */}

            <Grid>
                <Card style={{ maxWidth: 450, padding: '20px 5px', margin: '0 auto' }}>
                    <CardContent>
                        <Typography style={{ fontSize: 36, fontWeight: '600', color: '#5a5a5a' }} gutterBottom variant="h5">
                            Fale Conosco
                        </Typography>
                        <Typography style={{
                            color: '#5a5a5a',
                            fontWeight: '500',
                            fontSize: '0.9rem',
                            top:6
                        }} variant="body2" color="textSecondary" component="p" gutterBottom>
                            Preencha o formulário e nossa equipe entrará em contato em até 24 horas.
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Grid  container spacing={1}>
                                <Grid   style={{ marginTop: 3, marginBottom: 3 }} xs={12} sm={12} item>
                                    <TextField                                     
                                        placeholder="Nome completo"
                                        label="Nome completo"
                                        variant="outlined"
                                        fullWidth
                                        // required
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        error={!!formErrors.name}
                                        helperText={formErrors.name}
                                    />
                                </Grid>
                                <Grid   style={{ marginTop: 3, marginBottom: 3 }} item xs={12}>
                                    <TextField
                                        type="email"
                                        placeholder="Seu E-mail"
                                        label="Seu E-mail"
                                        variant="outlined"
                                        fullWidth
                                        // required
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        error={!!formErrors.email}
                                        helperText={formErrors.email}
                                    />
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <TextField
                                        type="number"
                                        placeholder="Seu telefone"
                                        label="Telefone"
                                        variant="outlined"
                                        fullWidth
                                        // required
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                        error={!!formErrors.phone}
                                        helperText={formErrors.phone}
                                    />
                                </Grid> */}
                                <Grid  style={{ marginTop: 3, marginBottom: 3 }} item xs={12}>
                                    <InputMask
                                        mask="(99) 9999-9999"
                                        maskChar="_"
                                        placeholder="Seu telefone"
                                        label="Telefone"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                    >
                                        {(inputProps) => (
                                            <TextField
                                                {...inputProps}
                                                error={!!formErrors.phone}
                                                helperText={formErrors.phone}
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                                <Grid  style={{ marginTop: 3, marginBottom: 3 }} item xs={12}>
                                    <TextField
                                        label="Mensagem"
                                        multiline
                                        rows={4}
                                        placeholder="Digite sua mensagem aqui"
                                        variant="outlined"
                                        fullWidth
                                        // required
                                        name="erroMessage"
                                        value={formData.erroMessage}
                                        onChange={handleInputChange}
                                        error={!!formErrors.erroMessage}
                                        helperText={formErrors.erroMessage}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button type="submit" variant="contained" color="primary" fullWidth>
                                        Enviar
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </div>
    );
}

export default App;
