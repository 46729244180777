import Swal from 'sweetalert2'

export function seetAlertSelfie(error) {//bioInformationID
    Swal.fire({
        text: 'Modal with a custom image.',
        imageUrl: require('../../src/assets/bioInformation/perfilId.jpeg'),
        imageWidth: 400,
        imageHeight: 400,
        imageAlt: 'Custom image',
        footer: '<a href="">Dúvidas e Sugestões - FAQ</a>',
        html:
            'Tire uma foto <b style="color:red;">como o exempo a cima</b>, essa foto será para sua caterinha.',

        showCloseButton: true,
        focusConfirm: false,
        confirmButtonText:
            '<i class="fa fa-thumbs-up"></i> Fechar',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonText:
            '<i class="fa fa-thumbs-down"></i>',
        cancelButtonAriaLabel: 'Thumbs down'
    })
}

export function seetAlertChekID(error) {
    Swal.fire({
        text: 'Modal with a custom image.',
        imageUrl: require('../../src/assets/bioInformation/identidade-rg.png'),
        imageWidth: "60%",
        imageHeight: "50%",
        imageAlt: 'Custom image',
        footer: '<a href="">Dúvidas e Sugestões - FAQ</a>',
        html:
            'Tire uma foto  <b style="color:red;">como o exempo a cima</b>, da sua  <b style="color:red;">identidade</b>' +
            ' para a sua validação.',

        showCloseButton: true,
        focusConfirm: false,
        confirmButtonText:
            '<i style="color:red;" class="fa fa-thumbs-up"></i> Fechar',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonText:
            '<i style="color:red;" class="fa fa-thumbs-down"></i>',
        cancelButtonAriaLabel: 'Thumbs down'
    })
}

export function reesendMensgemErro(error) {
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error, 
        footer: '<a href="">Dúvidas e Sugestões - FAQ</a>'
    })
}

export function reesendMensgemSucess(error) {
    Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: error, 
        footer: '<a href="">Dúvidas e Sugestões - FAQ</a>'
    })
}