import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Typography, Button, IconButton, Grid } from '@material-ui/core';
import { CheckImageField } from '../../FormFields';
import Webcam from "react-webcam";
// import atob from 'atob';
import useStyles from './styles';
import { seetAlertSelfie, seetAlertChekID, reesendMensgemErro } from '../../../services/alerts';
import { detectLabels, postAwsCompareFaces } from "../../../services/aws-rekognition"

import lottie from 'lottie-web';

import CameraAltIcon from '@material-ui/icons/CameraAlt'
import { Check } from '@material-ui/icons';

import LottieAnimation from '../../../assets/animations/tutorialPhoto';

const videoConstraintsMobille = {
    width: 500,
    height: 400,
    // facingMode: "user",
    facingMode: { exact: 'environment' },
};

const videoConstraints = {
    width: 500,
    height: 400,
    facingMode: "user",

};

export default function BioInformationForm(props) {
    const userAgent = navigator.userAgent;

    const { formField: { imageIdUser }, sendImageAws } = props;
    const classes = useStyles();
    const webcamRef = useRef(null);
    const webcamRefImageId = useRef(null);
    const [activeStep, setActiveStep] = useState(0);
    const [imageIdCheck, setImageIdCheck] = useState("")
    const [imageId, setImageId] = useState("");

    const [tutorialImageIdCheck, setTutorialImageIdCheck] = useState(false);
    const [tutorialImageIdCard, setTutorialImageIdCard] = useState(false);




    const captureIdCheck = useCallback(
        () => {
            const image = webcamRef.current.getScreenshot();
            return image;
        },
        [webcamRef]
    );

    const captureImageId = useCallback(
        () => {
            const image = webcamRefImageId.current.getScreenshot();
            setImageId(image)
            return image
        },
        [webcamRefImageId]
    );

    function renderSwitch(param) {
        switch (param) {
            case 0:
                if (tutorialImageIdCheck) {
                    if (/Mobi|Android/i.test(userAgent)) {
                        // Código para dispositivos móveis (smartphones)
                        return (
                            <div style={{ position: 'relative' }}>
                                <Webcam
                                    audio={false}
                                    style={{
                                        right: 0,
                                        height: "40vh",
                                        width: "100%",
                                        objectFit: "fill",
                                        borderRadius: 5,
                                    }}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={videoConstraintsMobille}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: '80%',
                                        height: '64%',
                                        borderColor: 'red',
                                        borderStyle: 'dotted',
                                        borderWidth: 2,
                                    }}
                                />
                            </div>
                        );
                    } else {
                        return (
                            <div style={{ position: 'relative' }}>
                                <Webcam
                                    audio={false}
                                    style={{
                                        right: 0,
                                        height: "40vh",
                                        width: "100%",
                                        objectFit: "fill",
                                        borderRadius: 5,
                                    }}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={videoConstraints}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: '80%',
                                        height: '64%',
                                        borderColor: 'red',
                                        borderStyle: 'dotted',
                                        borderWidth: 2,
                                    }}
                                />
                            </div>
                        );
                    }
                } else {
                    checkTimeUtorial();
                    if (/Mobi|Android/i.test(userAgent)) {
                        // Código para dispositivos móveis (smartphones)
                        return <LottieAnimation path="https://assets7.lottiefiles.com/packages/lf20_7ovs9for.json" />
                    } else if (/iPad|Tablet/i.test(userAgent)) {
                        // Código para tablets
                        return <LottieAnimation path="https://assets7.lottiefiles.com/packages/lf20_7ovs9for.json" />
                    } else {
                        // Código para navegadores desktop
                        return <LottieAnimation path="https://assets7.lottiefiles.com/packages/lf20_bjodpn1u.json" />
                    }
                }
            case 1:
                if (tutorialImageIdCard) {
                    return (
                        <div style={{ position: 'relative' }}>
                            <Webcam
                                audio={false}
                                style={{
                                    right: 0,
                                    height: "40vh",
                                    width: "100%",
                                    objectFit: "fill",
                                    borderRadius: 5,
                                }}
                                ref={webcamRefImageId}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '50%', // Ajuste o valor para alterar a largura da elipse
                                    height: '70%', // Ajuste o valor para alterar a altura da elipse
                                    borderRadius: '50% / 40%', // Ajuste os valores para alterar a proporção da elipse
                                    border: '2px solid red',
                                }}
                            />
                        </div>
                    );
                } else {
                    checkTimeUtorialIdcard()
                    // return <LottieAnimation path="https://assets3.lottiefiles.com/packages/lf20_1tb4qwqf.json" />

                    if (/Mobi|Android/i.test(userAgent)) {
                        // Código para dispositivos móveis (smartphones)
                        return <LottieAnimation path="https://assets7.lottiefiles.com/packages/lf20_lkk9iict.json" />
                    } else if (/iPad|Tablet/i.test(userAgent)) {
                        // Código para tablets
                        return <LottieAnimation path="https://assets7.lottiefiles.com/packages/lf20_lkk9iict.json" />
                    } else {
                        // Código para navegadores desktop
                        return <LottieAnimation path="https://assets3.lottiefiles.com/packages/lf20_1tb4qwqf.json" />
                    }
                }
            case 2:
                return <img
                    style={{
                        right: 0,
                        height: "40vh",
                        width: "100%",
                        objectFit: "fill",
                        borderRadius: 5,
                    }}
                    src={imageId} />
            case 3:
                return <div
                    style={{
                        right: 0,
                        height: "40vh",
                        width: "100%",
                        objectFit: "fill",
                        borderRadius: 5,

                    }}
                    src={imageId} > vc finalizou a serie de fotos</div>
            default:
                return 'erro';
        }
    }

    function checkTimeUtorial() {
        setTimeout(() => {
            setTutorialImageIdCheck(!tutorialImageIdCheck);
        }, 4900);
    }

    function checkTimeUtorialIdcard() {
        setTimeout(() => {
            setTutorialImageIdCard(!tutorialImageIdCard);
        }, 4900);
    }

    function stepsCaptureImage() {
        switch (activeStep) {
            case 0:
                const result = captureIdCheck();
                const imageConvert = result.split(',')[1];
                const isIdCardBrasil = detectLabels(imageConvert);
                setImageIdCheck(imageConvert);
                isIdCardBrasil
                    .then((res) => {
                        if (res === true) {
                            setTimeout(() => {
                                setActiveStep(activeStep + 1);
                            }, 1000);
                            //seetAlertSelfie();
                        } else {
                            reesendMensgemErro('Documento inválido');
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })

                break;
            case 1:
                const resImageId = captureImageId();
                const imageIdConvert = resImageId.split(',')[1];
                const similarityScore = postAwsCompareFaces(imageIdCheck, imageIdConvert)
                similarityScore
                    .then((res) => {
                        res.similarityScore > 90 ? setActiveStep(activeStep + 1) : reesendMensgemErro('As pessoas são diferentes nas imagens');
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                // setActiveStep(activeStep + 1);
                break;
            default:
                return 'erro';
        }
    }


    function tutorialTitles(params) {
        switch (params) {
            case 0:
                // return (
                //     <Typography className={classes.tutorialTitles} variant="h5" gutterBottom>
                //         Tire uma selfie segurando a carteria de identidade
                //     </Typography>
                // )
                if (/Mobi|Android/i.test(userAgent)) {
                    // Código para dispositivos móveis (smartphones)
                    return (
                        <Typography className={classes.tutorialTitles} variant="h5" gutterBottom>
                            Tire uma foto de sua carteria de identidade 
                        </Typography>
                    )
                } else if (/iPad|Tablet/i.test(userAgent)) {
                    return (
                        <Typography className={classes.tutorialTitles} variant="h5" gutterBottom>
                            Tire uma selfie segurando a carteria de identidade
                        </Typography>
                    )
                } else {
                    // Código para navegadores desktop
                    return (
                        <Typography className={classes.tutorialTitles} variant="h5" gutterBottom>
                            Tire uma selfie segurando a carteria de identidade
                        </Typography>
                    )
                }
            case 1:
                return (
                    <Typography className={classes.tutorialTitles} variant="h5" gutterBottom>
                        Agora tire uma selfie para sua carterinha do PDT
                    </Typography>
                )
            case 2:
                return (
                    <Typography className={classes.tutorialTitles} variant="h5" gutterBottom>
                        Essa foto será usada para sua indetidade no PDT
                    </Typography>
                )
            case 3:
                return "3"
            default:
                return 'erro';
        }
    }

    function tutorialButtons(params) {
        switch (params) {
            case 0:
                if (tutorialImageIdCheck) {
                    return <Button onClick={() => { stepsCaptureImage() }} type="button" variant="contained" color="primary" className={classes.buttonValidation}>Selfie validação</Button>
                } else {
                    return null;
                }
            // return (
            //     <IconButton
            //         onClick={() => { stepsCaptureImage() }}
            //         color="primary"
            //         className={classes.buttonValidation}
            //     >
            //         <CameraAltIcon />
            //     </IconButton>
            // )
            case 1:
                if (tutorialImageIdCard) {
                    return <Button onClick={() => { stepsCaptureImage() }} type="button" variant="contained" color="primary" className={classes.buttonValidation}>Selfie caretinha</Button>
                } else {
                    return null;
                }
            case 2:
                return <Button onClick={() => { setActiveStep(activeStep - 1) }} type="button" variant="contained" color="primary" className={classes.buttonValidation}>recusar foto</Button>
            default:
                return 'erro';
        }
    }


    return (
        <React.Fragment>
            {tutorialTitles(activeStep)}
            {renderSwitch(activeStep)}
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    {tutorialButtons(activeStep)}
                    <CheckImageField
                        imageId={imageId}
                        sendImageAws={sendImageAws}
                        classes={classes}
                        activeStep={activeStep}
                        name={imageIdUser.name}
                        label={imageIdUser.label}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                    <CheckImageField
                        imageId={imageId}
                        sendImageAws={sendImageAws}
                        classes={classes}
                        activeStep={activeStep}
                        name={imageIdUser.name}
                        label={imageIdUser.label}
                    />
                </Grid> */}
            </Grid>
        </React.Fragment >
    );
}

