import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { TextField } from '@material-ui/core';
import InputMask from "react-input-mask";

export default function InputField(props) {
  const { mask,inputRef, errorText, typeFormat, ...rest } = props;
  const [field, meta] = useField(props);

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }

  return (
    <InputMask
      mask={mask}
      maskChar=" "   
      error={meta.touched && meta.error && true}
      helperText={_renderHelperText()}    
      {...field}
      {...rest}
    >
      {
        () => <TextField
          type={typeFormat || "text"}
          error={meta.touched && meta.error && true}
          helperText={_renderHelperText()}
          inputRef={inputRef}
          {...field}
          {...rest} />
      }
    </InputMask>
  );
}


