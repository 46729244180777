import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
  appBar: {
    flexGrow: 1,
    position: 'relative',
    background: '#263272',
    color: '#f5f5f5',
    //fontWeight: '600',
     fontFamily: "Roboto",    
  }
}));
