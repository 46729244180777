
export default function createAffiliated(values, token) {
  //console.log(values)

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "personalInformation": {
      "fullname": values.firstName,
      "cpf": values.cpf,
      "imageWallet": values.imageWallet,
      "birthDate": values.birthDate,
      "motherFullname": values.mothersName,
      "phoneNumber": values.whatsapp,
      "job": values.profession,
      "religion": values.religion,
      "email": values.email,
      "gender": values.gender,
      "schoolingLevel": values.levelEducation
    },
    "address": {
      "cep": values.cep,
      "state": values.uf,
      "city": values.city,
      "neighborhood": values.bairro,
      "street": values.logradouro,
      "houseNumber": values.number,
      "complement": values.addressComplement
    },
    "electionInformation": {
      "voterRegistration": values.voterRegistration,
      "electionZone": values.electoralZone,
      "section": values.section,
      "image": "www.teste.com/imagem.jpeg"
    }
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  createAffiliatedAPI(requestOptions, values)
  //resendConfirmationCode(values.email);
}

function createAffiliatedAPI(requestOptions, values) {
  fetch("https://smtp.api-pdt.com.br/v1/affiliated", requestOptions)
    .then(response => response.text())
    .then(result => sendWelcomeEmail(values.firstName, values.email))
    .catch(error => console.log('error', error));
}


function sendWelcomeEmail(name, email) {

  // resendConfirmationCode(email);

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "email": email,
    "name": name
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  fetch("https://smtp.api-pdt.com.br/v1/email/welcome", requestOptions)
    .then(response => response.text())
    // .then(result => console.log(result))
    .catch(error => console.log('error', error));

}

