import React from 'react';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import './styles.css'; // Importe o arquivo CSS com as classes definidas

function ResponsiveStepper(props) {

  const { activeStep , steps} = props;

  return (
    <div className="stepper-container">
      {/* <Stepper  className="stepper" alternativeLabel>
        <Step>
          <StepLabel>Passo 1</StepLabel>
        </Step>
        <Step>
          <StepLabel>Passo 2</StepLabel>
        </Step>
        <Step>
          <StepLabel>Passo 3</StepLabel>
        </Step>
      </Stepper> */}
      <Stepper activeStep={activeStep}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

export default ResponsiveStepper;