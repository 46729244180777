import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  FormControl,
  FormHelperText,
  Button,
  Grid
} from '@material-ui/core';

export default function CheckImageField(props) {
  const { label, activeStep, classes, sendImageAws, imageId, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return <FormHelperText style={{ color: 'red', fontSize: 12, marginLeft: 12 }}>{error}</FormHelperText>;
    }
  }

  function _onChange(e) {
    setValue("e.target.checked");
    sendImageAws(imageId);
  }

  return (
    <FormControl {...rest}>
      {/* {activeStep === 2 ? <Button onClick={_onChange} type="button" variant="contained" color="primary" className={classes.buttonLikePicture}>aprovar foto</Button> : null}
      <div>
        {_renderHelperText()}
      </div> */}

      <Grid className={classes.gridLikePicture} container spacing={3}>
        <Grid item xs={12} sm={12}>
          {activeStep === 2 ? <Button onClick={_onChange} type="button" variant="contained" color="primary" className={classes.buttonLikePicture}>aprovar foto</Button> : null}
        </Grid>
        <Grid className={classes.renderHelperText} item xs={12} sm={12}>
          {_renderHelperText()}
        </Grid>
      </Grid>
    </FormControl>
  );
}
