import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import ResponsiveStepper from '../../components/ResponsiveStepper';

import AddressForm from './Forms/AddressForm';
import ElectoralDataForm from './Forms/ElectoralDataForm';
import BioInformationForm from './Forms/BioInformationForm';
import AuthenticationForm from './Forms/AuthenticationForm';
import CheckoutSuccess from './CheckoutSuccess';

import validationSchema from './FormModel/validationSchema';
import checkoutFormModel from './FormModel/checkoutFormModel';
import formInitialValues from './FormModel/formInitialValues';

import useStyles from './styles';
import getCep from '../../../src/services/viacep'
import { Auth } from 'aws-amplify';
import  ChatWidget  from '../../components/chat/ChatWidget'
import createAffiliated from '../../services/pdt-api';
import Swal from 'sweetalert2';
import Success from '../../components/Success/'

const steps = ['Dados Pessoais', 'Dados Eleitorais', 'Dados Biométricos', 'Final'];
const { formId, formField } = checkoutFormModel;


export default function CheckoutPage(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [resultApi, setResultApi] = useState({});
  const [isValidAwsAuth, setIsvalidAwsAuth] = useState(false);
  const [loading, setLoading] = useState(false);
  const [awsBuketImage, setAwsBuketImage] = useState('');
  const [checkoutObject, setCheckoutObject] = useState({});

  async function signUp(username, password, values, actions) {
    try {
      const { user } = await Auth.signUp({
        username,
        password,
        attributes: {
          //email,          // optional
          //phone_number,   // optional - E.164 number convention
          // other custom attributes 
        },
        autoSignIn: { // optional - enables auto sign in after user is confirmed
          enabled: true,
        }
      });
      setIsvalidAwsAuth(true)
      // setLoading(createTrue)
      setTimeout(() => {
        setActiveStep(activeStep + 1);
        _submitForm(values, actions)
      }, 2500);
      return true;
    } catch (error) {
      seetAlert(error)
      return false;
    }
  }

  function getCepField(cep) {
    const result = getCep(cep);
    result
      .then((el) => {
        setResultApi(el.data);
      })
      .catch((error) => { console.log(error) })
  }

  function sendImageAws(image) {
    const formData = new FormData();
    formData.append('file', dataURItoBlob(image));

    fetch('https://smtp.api-pdt.com.br/v1/upload', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => { setAwsBuketImage(data.results) })
      .catch((erro) => {
        //setActiveStep(2)
        console.log(erro)

      })
  }

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  };

  function _renderStepContent(step) {
    // switch (2) {
     switch (step) {
      case 0:
        return <AddressForm resultApi={resultApi} getCepField={getCepField} formField={formField} />;
      case 1:
        return <ElectoralDataForm formField={formField} />;
      case 2:
        return <BioInformationForm formField={formField} sendImageAws={sendImageAws} />;
      case 3:
        return <AuthenticationForm formField={formField} />;
      default:
        return <div>Not Found</div>;
    }
  }

  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    await _sleep(1000);
    actions.setSubmitting(false);
    values.uf = resultApi.uf || 'teste';
    values.city = resultApi.localidade;
    values.country = "Brasil";
    values.localidade = resultApi.localidade;
    values.logradouro = resultApi.logradouro;
    values.cep = resultApi.cep;
    values.bairro = resultApi.bairro;
    values.imageWallet = awsBuketImage;
    let dataString = values.birthday;
    let partesDaData = dataString.split('/');
    let data = new Date(partesDaData[2], partesDaData[1] - 1, partesDaData[0]);
    let dataFormatada = data.toISOString();
    values.birthDate = dataFormatada;
    var stringNumero = values.cep;;
    var numeroSemTraco = parseInt(stringNumero.replace("-", ""));
    console.log(numeroSemTraco); // Saída: 21750390
    values.cep = numeroSemTraco;
    var stringNumeroCpf = values.cpf;
    var numeroCpf = parseInt(stringNumeroCpf.replace(/[^\d]/g, ""));
    values.cpf = numeroCpf;
    var stringNumeroWhatsapp = values.whatsapp;;
    var numeroZap = parseInt(stringNumeroWhatsapp.replace(/[^\d]/g, ""));
    values.whatsapp = numeroZap;
    createAffiliated(values)
    setCheckoutObject(values)
    setActiveStep(activeStep + 1);
    // console.log(values)
  };

  function seetAlert(error) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: getErrorAuthAws(error),
      footer: '<a href="">Dúvidas e Sugestões - FAQ</a>'
    })
  }

  function getErrorAuthAws(error) {
    if (error.code === "UsernameExistsException") {
      return 'Esse endereço de e-mail já foi usado!'
    } else {
      console.log(error)
      return 'Erro de Autenticação'
    }
  }

  function _handleSubmit(values, actions) {
    if (activeStep == 3) {
      signUp(values.email, values.password, values, actions)
      actions.setTouched({});
      actions.setSubmitting(false);
      if (isValidAwsAuth) {
        // _submitForm(values, actions);
      } else {
        setActiveStep(3)
      }
    } else {
      if (isLastStep) {
        // _submitForm(values, actions);
      } else {
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
      }
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  function checkoutSuccessOrResendpassword(value, userData) {
    if (value) {
      return <CheckoutSuccess data={userData} />
    } else {
      return <p>Componente para enviar o codigo via email</p>
    }
  }

  // function checkoutSucess(data){
  //    return <Success data={data} />
  // }

  return (
    <React.Fragment>
      <Typography className={classes.titleMain} component="h1" variant="h4" align="center">
        {activeStep < steps.length ? "Cadastro de Filiação do PDT" : null}
        <ChatWidget/> 
      </Typography>  
      {activeStep < steps.length ? <ResponsiveStepper steps={steps} activeStep={activeStep} /> : null}
      <React.Fragment>
        {/* {true ? ( */}
        {activeStep === steps.length ? (
          <Success data={checkoutObject} />
        ) : (
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form id={formId}>
                {_renderStepContent(activeStep)}

                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={_handleBack} className={classes.button}>
                      Voltar
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    <Button
                      disabled={loading}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {isLastStep ? 'Finalizar' : 'Próximo'}
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}