import React, { useRef, useState, useEffect } from 'react';
import AuthCode from 'react-auth-code-input';
import { Typography, Grid, TextField, Button } from '@material-ui/core';
import { resendConfirmationCode, confirmSignUp } from "../../services/aws-amplify"
import { reesendMensgemErro, reesendMensgemSucess } from '../../services/alerts' 
import useStyles from './styles';

const steps = ['Reenvio de Código de Confirmação', 'Reenvio de Código de Confirmação', 'Reenvio de Código de Confirmação'];

const ImageLogo = (width, height, porcent) => {
    return (
        <img src="https://www.pdt.org.br/wp-content/uploads/2021/12/LGO.jpg"
            alt="Nature"
            style={{ width: porcent, height: "auto" }}
            className="responsive"
            width={width} height={height} />
    )
}

const App = (props) => {
    const { data } = props;
    //const { getResult } = props;
    const classes = useStyles();
    const AuthInputRef = useRef(null);
    const [result, setResult] = useState('');
    const [isPassword, setIsPassword] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [allowedCharacters, setAllowedCharacters] = useState('alphanumeric');
    const [activeStep, setActiveStep] = useState(1);
    const [inputValue, setInputValue] = useState('');
    // const [isValid, setIsValid] = useState(false); 
    const [code, setCode] = useState('');  

    function showSteps() {
        switch (activeStep) {
            case 0:
                return (
                    <TextField
                        type="email"
                        label="Digite seu E-mail aqui"
                        variant="outlined"
                        value={inputValue}
                        onChange={handleChange}
                        className={classes.textField}
                    />)
            case 1:
                return <AuthCode
                    allowedCharacters='numeric'
                    key={allowedCharacters}
                    //allowedCharacters={allowedCharacters}
                    ref={AuthInputRef}
                    onChange={handleOnChange}
                    containerClassName='container'
                    inputClassName='input'
                    isPassword={isPassword}
                    disabled={disabled}
                />
            case 2:
                return (<React.Fragment>
                    {ImageLogo("600", "600", "100%")}
                    <Typography className={{}} variant="h5" gutterBottom>
                        Envio concluído!
                    </Typography>
                    <Typography variant="subtitle1">
                        Recebemos a sua solicitação de filiação ao PDT! Agora, basta aguardar o nosso e-mail com a confirmação de sua filiação.
                        Esperamos em breve ter você conosco fazendo parte da nossa história!
                    </Typography>
                </React.Fragment >)

            default:
                console.log("defalt case")
        }
    }

    function showBunttons() {
        switch (activeStep) {
            case 0:
                return <Button onClick={() => { handleSubmit() }} variant="contained" color="primary" className={classes.button}> Reenviar E-mail</Button>
            case 1:
                return <Button onClick={() => { sendCode() }} variant="contained" color="primary" className={classes.button}> Enviar Codigo</Button>
            case 2:               
                return null
            default:
                break;
        }
    }

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSubmit = () => {
        const result = resendConfirmationCode(data.email);
        result
            .then((res) => { alert(res) })
            .catch((err) => { alert(err) })

    };  

    function confimartionSendCode(mensage) {
        switch (mensage) {
            case "code resent successfully":
                reesendMensgemSucess("Código reenviado com sucesso.");
                setActiveStep(activeStep + 1)
                break;
            case "Confirmation code cannot be empty":
                reesendMensgemErro("O código de confirmação não pode ficar em branco.");
                break;
            case "User cannot be confirmed. Current status is CONFIRMED":
                reesendMensgemErro("O usuário já foi confirmado");
                break;
            case "Invalid verification code provided, please try again.":
                reesendMensgemErro("Código de verificação inválido fornecido, tente novamente.");
                break;
            default:
                console.log("defalt case")
        }
    }

    const handleOnChange = (code) => {
        setCode(code);      
    };  

    const sendCode = () => {
        const result = confirmSignUp(data.email, code)
        result
            .then((res) => { confimartionSendCode(res) })
            .catch((err) => { confimartionSendCode(err) })
    }

    return (
        <React.Fragment >
            <Typography className={classes.titleMain} align="center" gutterBottom>
                {steps[activeStep]}
            </Typography>
            <Grid container spacing={3} align="center">
                <Grid item xs={12} sm={12} className={classes.imageSmall}>
                    {activeStep < 2 ? ImageLogo("60", "60", "30%") : null}
                </Grid>
                <Grid item xs={12} sm={12}>
                    {showSteps()}
                    {activeStep === 1 ? <Typography className={classes.titleFooter} variant="subtitle1"> Digite aqui o codigo que foi enviado para seu e-mail.
                    </Typography> : null}
                </Grid>
                <Grid item xs={12} sm={12} align="center">
                    {showBunttons()}
                </Grid>
                <Grid item xs={12} sm={12} className={classes.footerDescription} align="center">
                    {activeStep < 2 ?
                        <Typography className={classes.titleFooter} variant="h8" >
                            Certifique-se de verificar também a pasta de spam ou lixo eletrônico caso não encontre o e-mail em sua caixa de entrada.
                        </Typography>
                        : null}
                </Grid>
            </Grid>       
        </React.Fragment>

    )
}

export default App;


  