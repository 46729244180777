import { Auth } from 'aws-amplify';

export async function signUp(username, password) {
    try {
        const { user } = await Auth.signUp({
            username,
            password,
            attributes: {
                //email,          // optional
                //phone_number,   // optional - E.164 number convention
                // other custom attributes 
            },
            autoSignIn: { // optional - enables auto sign in after user is confirmed
                enabled: true,
            }
        });
        return user
    } catch (error) {
        return error
    }
}

export async function resendConfirmationCode(username) {
    try {
        await Auth.resendSignUp(username);
        return 'code resent successfully';
    } catch (err) {
        const errorMessage = err.message || 'An error occurred';
        return errorMessage;
    }
}

export async function confirmSignUp(username, code) {
    try {
        await Auth.confirmSignUp(username, code);
        return 'code resent successfully';
    } catch (err) {
        const errorMessage = err.message || 'An error occurred';
        return errorMessage;
    }
}