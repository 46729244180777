export const levelEducations = [
    {
        value: 'Ensino fundamental completo',
        label: 'Ensino fundamental completo'
    },   
    {
        value: 'Ensino fundamental incompleto',
        label: 'Ensino fundamental incompleto'
    },
    {
        value: 'Ensino médio completo',
        label: 'Ensino médio completo'
    },
    {
        value: 'Ensino médio incompleto',
        label: 'Ensino médio incompleto'
    },    
    {
        value: 'Superior completo',
        label: 'Superior completo'
    },
    {
        value: 'Superior incompleto',
        label: 'Superior incompleto'
    },
    {
        value: 'Pós-graduação',
        label: 'Pós-graduação'
    },
    {
        value: 'Doutorado',
        label: 'Doutorado'
    },
    {
        value: 'Pós-doutorado',
        label: 'Pós-doutorado'
    }
];


 

export const genders = [
    {
        value: 'Homem',
        label: 'Homem'
    },
    {
        value: 'Mulher',
        label: 'Mulher'
    },
    {
        value: 'Homem trans',
        label: 'Homem trans'
    },
    {
        value: 'Mulher trans',
        label: 'Mulher trans'
    },
    {
        value: 'Travesti',
        label: 'Travesti'
    },
    {
        value: 'Não binário',
        label: 'Não binário'
    },
];


export const religions = [
    {
        value: 'Sem religião',
        label: 'Sem religião'
    },
    {
        value: 'Evangélica',
        label: 'Evangélica'
    },
    {
        value: 'Budismo',
        label: 'Budismo'
    },
    {
        value: 'Candomblé',
        label: 'Candomblé'
    },
    {
        value: 'Católica',
        label: 'Católica'
    },
    {
        value: 'Espírita',
        label: 'Espírita'
    },
    {
        value: 'Islamismo',
        label: 'Islamismo'
    },
    {
        value: 'Judaísmo',
        label: 'Judaísmo'
    },
    {
        value: 'Umbanda',
        label: 'Umbanda'
    },
    {
        value: 'Hiduísmo',
        label: 'Hiduísmo'
    },
    {
        value: 'Outras',
        label: 'Outras'
    },

];