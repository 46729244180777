import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
  appBar: {
    flexGrow: 1,
    position: 'relative',
    background: '#263272',
    color: '#f5f5f5',
    fontWeight: '600'
  },
  buttonValidation: {
    color: '#fff',
    background: '#eb1820',
    marginTop: 12,
    display: 'flex',
    //justifyContent: 'center', // Centraliza horizontalmente
    alignItems: 'center', //
    alingSelf: 'center'
  },
  buttonLikePicture: {
    color: '#fff',
    background: '#008000',
    marginLeft: -12,
    top: 16
  },
  buttonDontLikePicture: {
    color: '#fff',
    background: '#eb1820',
    marginLeft: 0,

  },
  titlePersonalData: {
    color: "#5a5a5a",
  },
  tutorialTitles: {
    // flexGrow: 1,
    position: 'relative',
    //background: '#263272',
    color: '#5a5a5a',
    fontWeight: '600',
    marginTop: 6,
    marginBottom: 8,
  },
  gridLikePicture: {
    //background: 'gray',
    marginLeft: 0
  },
  renderHelperText: {
    //background: 'gray',
    marginLeft: -16,
  }

}));