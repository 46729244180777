import React from 'react';
import MaterialLayout from './components/Layout/MaterialLayout';
// import CheckoutPage from './components/CheckoutPage';
import { Amplify } from 'aws-amplify';
import aws_exports from './aws-exports'
import Route from '../src/routers' 
Amplify.configure(aws_exports);

function App() {
  return (
    <div>
      <MaterialLayout>
        <Route />       
      </MaterialLayout>
    </div>
  );
}

export default App;