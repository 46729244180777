import React, { useState } from 'react';
import { Typography, Grid, TextField, Button } from '@material-ui/core';
import useStyles from './styles';
import { Auth } from "aws-amplify";

import TwoFactorAuthentication from "../../TwoFactorAuthentication"
// import createAffiliated from '../../../services/pdt-api';
import { reesendMensgemErro, reesendMensgemSucess } from "../../../services/alerts"

function CheckoutSuccess(props) {

  const { data } = props;
  const classes = useStyles(); 
  const [text, setText] = useState('');
  const [isValid, setIsValid] = useState(false);

  const handleClick = () => {
    if (text.length < 6) {
      reesendMensgemErro("É necessário um código de 6 digitos!")
    } else {
      confirmSignUp()
    }
  };

  async function confirmSignUp() {
    try {
      await Auth.confirmSignUp(data.email, text); 
      setIsValid(true);
      reesendMensgemSucess("O código foi confirmado com sucesso."); 
    } catch (error) {
      reesendMensgemErro("O código está incorreto."); 
    }
  }

  async function resendConfirmationCode() {
    try {
      await Auth.resendSignUp(data.email);
      reesendMensgemSucess("O código foi reenviado com sucesso.");
    } catch (err) {
      reesendMensgemErro('Error ao reenviar o código.');
    }
  }

  function getResultInsideTwoFactorAuthentication(code) {
    //console.log(code)
    setText(code);  
  }

  if (isValid) {
    return (
      <React.Fragment>
        <img src="https://www.pdt.org.br/wp-content/uploads/2021/12/LGO.jpg"
          alt="Nature"
          style={{ width: "100%", height: "auto" }}
          className="responsive"
          width="600" height="600"></img>
        <Typography className={classes.titleMain} variant="h5" gutterBottom>
          Envio concluído!
        </Typography>
        <Typography variant="subtitle1">
          Recebemos a sua solicitação de filiação ao PDT! Agora, basta aguardar o nosso e-mail com a confirmação de sua filiação.
          Esperamos em breve ter você conosco fazendo parte da nossa história!
        </Typography>
      </React.Fragment >
    );
  } else {
    return (
      <React.Fragment>
        <Typography className={classes.titleMain} variant="h4" gutterBottom>
          Um codigo foi enviado para seu e-mail!
        </Typography>    
        <Grid className={classes.buttonBox}  container spacing={3}>
          <Grid item xs={12} sm={12}>        
            <TwoFactorAuthentication getResult={getResultInsideTwoFactorAuthentication} />
          </Grid>
          <Grid item xs={12} sm={4}>
            < Button onClick={handleClick} type="button" variant="contained" color="primary">Enviar código</Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            < Button onClick={resendConfirmationCode} type="button" variant="contained" color="primary" className={classes.buttonDontLikePicture}>Reenviar código</Button>
          </Grid>
        </Grid>
      </React.Fragment >
    )
  }
}

export default CheckoutSuccess;
