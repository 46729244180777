import * as Yup from 'yup';
import moment from 'moment';
import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    imageIdUser,
    email,
    password,
    firstName,
    gender,
    profession,
    religion,
    levelEducation,   
    number,
    addressComplement,
    city,
    zipcode,
    mothersName,
    voterRegistration,
    country,
    whatsapp,
    birthday, 
    electoralZone,
    section,
    cpf, 
    termsAndConditions
  }
} = checkoutFormModel;

//const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;

export default [
  Yup.object().shape({
    [firstName.name]: Yup.string()
      .required(`${firstName.requiredErrorMsg}`)
      .test('len', `${firstName.invalidErrorMsg}`, val => val && val.trim().length > 7),
    [number.name]: Yup.string()
      .required(`${number.requiredErrorMsg}`),
    [addressComplement.name]: Yup.string(),
      // .required(`${addressComplement.requiredErrorMsg}`),
    [city.name]: Yup.string()
      .nullable(),
    [zipcode.name]: Yup.string()
      .required(`${zipcode.requiredErrorMsg}`)
      .test(
        'len',
        `${zipcode.invalidErrorMsg}`,
        val => val && val.length === 8
      ),
    [whatsapp.name]: Yup.string()
      .required(`${whatsapp.requiredErrorMsg}`)
      .test('len', `${whatsapp.invalidErrorMsg}`, val => val && val.trim().length > 15),
    [birthday.name]: Yup.string()
      .required(`${birthday.requiredErrorMsg}`)
      .test('len', `${birthday.invalidErrorMsg}`, val => val && val.trim().length > 9),
  }),
  Yup.object().shape({
    [mothersName.name]: Yup.string()
      .required(`${mothersName.requiredErrorMsg}`)
      .test('len', `${firstName.invalidErrorMsg}`, val => val && val.trim().length > 7),
    [voterRegistration.name]: Yup.string()
      .required(`${voterRegistration.requiredErrorMsg}`)
      .test('len', `${voterRegistration.invalidErrorMsg}`, val => val && val.trim().length > 14),
    [electoralZone.name]: Yup.string()
      .required(`${electoralZone.requiredErrorMsg}`)
      .test('len', `${electoralZone.invalidErrorMsg}`, val => val && val.trim().length > 2),
    [section.name]: Yup.string()
      .required(`${section.requiredErrorMsg}`)
      .test('len', `${section.invalidErrorMsg}`, val => val && val.trim().length > 2),
    [cpf.name]: Yup.string()
      .required(`${cpf.requiredErrorMsg}`)
      .test('len', `${cpf.invalidErrorMsg}`, val => val && val.trim().length > 13),
    [gender.name]: Yup.string().required(`${gender.requiredErrorMsg}`),
    [levelEducation.name]: Yup.string().required(`${levelEducation.requiredErrorMsg}`),
    [profession.name]: Yup.string().required(`${levelEducation.requiredErrorMsg}`),
    [religion.name]: Yup.string().required(`${religion.requiredErrorMsg}`),   
  }),
  Yup.object().shape({
   [imageIdUser.name]: Yup.string().required(`${imageIdUser.requiredErrorMsg}`),    
  }),
  Yup.object().shape({
    [email.name]: Yup.string()
    .required(`${email.requiredErrorMsg}`)
    .email(`${email.invalidErrorMsg}`),
    [password.name]: Yup.string()
    .required(`${password.requiredErrorMsg}`)
    .test('len', `${password.invalidErrorMsg}`, val => val && val.length > 7),
    [termsAndConditions.name]: Yup.string()
    .required(`${termsAndConditions.requiredErrorMsg}`)
  })
];